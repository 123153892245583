<template>
  <div class="my-2">
    <b-row>
      <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
        <label>{{ $t('Show') }}</label>
        <v-select v-model="perPageDefault" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="pageOptions"
                  :clearable="false" class="per-page-selector d-inline-block mx-50"
        />
        <label>{{ $t('Entries') }}</label>
      </b-col>
      <b-col cols="12" md="6">
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input v-model="filter.input" class="d-inline-block" :placeholder="$t('Search...')"/>

          <slot></slot>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge, BButton, BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup, BInputGroupAppend, BOverlay,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'TableHeader',
  components: {
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    vSelect
  },
  props: {
    pageOptions: {
      type: Array,
      default: () => [10, 25, 50, 100],
    },
    perPage: {
      type: Number,
      default: () => 10
    },
    filter: {
      type: Object,
      default: () => {
      },
      required: true
    }
  },
  computed: {
    perPageDefault: {
      get: function(){
        return this.perPage;
      },
      set: function(newValue){
        this.$emit('update:per-page', newValue)
      }
    },
  }
}
</script>

<style scoped>

</style>
