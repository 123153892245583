<template>
  <div class="mx-2 mb-2">
    <b-row>
      <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">{{ $t('Showing') }} {{
                (perPage * (currentPage - 1) + (perPage && length > 0 ? 1 : 0))
              }}  {{ $t('to') }}  {{ (length < perPage ? length : perPage * (currentPage - 1) + perPage) }} {{ $t('of') }}
              {{ length }} {{ $t('entries') }}</span>
      </b-col>
      <!-- Pagination -->
      <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
        <b-pagination
            v-model="currentPageDefault   "
            :total-rows="length"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18"/>
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18"/>
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge, BButton, BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup, BInputGroupAppend, BOverlay,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'TableFooter',
  components: {
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    vSelect
  },
  props: {
    length: {
      type: Number,
      default: () => 0,
      required: true
    },
    perPage: {
      type: Number,
      default: () => 0,
      required: true
    },
    currentPage: {
      type: Number,
      default: () => 1  ,
      required: true
    },
  },
  computed: {
    currentPageDefault: {
      get: function(){
        return this.currentPage;
      },
      set: function(newValue){
        this.$emit('update:current-page', newValue)
      }
    },
  }
  // data() {
  //   return {
  //     loaded: false,
  //     perPage: 10,
  //     pageOptions: [10, 25, 50, 100],
  //     totalRows: 1,
  //     currentPage: 1,
  //   }
  // }
}
</script>

<style scoped>

</style>
